export const announces = [
    {date: '2024-08-20',
        text: 'https://drago.tw 網站開站日，這裡將持續累積完整真實的自己。',
        // h: 'https://forms.gle/dCEW3m3a1pJfj5kP7'
    },
    {date: '2024-08-20', 
        text: '感謝 https://github.com/bestian/playback 網站模板OpenSource分享，讓這裡有個簡單的開始。',
        h: 'https://github.com/bestian/playback'}
//  {date: '2024-07-31', text: '八月工作坊已確認成行，延長報名至08/06', h: 'https://forms.gle/6yQVzVvWew6vQ1eH8'},
//  {date: '2024-07-18', text: '八月工作坊籌備中：有興趣的朋友請先空出08/08, 08/22上午時段'},


]